<template>
  <div class="right">
    <span class="spen">客户经理</span>
    <Loading v-if="isLoading" />
    <div class="user" v-else>
      <div class="uese_pic">
        <div class="img">
          <Uimage :src="order_list.headPhoto" alt="" />
        </div>
      </div>
      <div class="user_job">
        <div class="user_list">
          <img src="../../../assets/order/user_name.png" alt="" />
          <div class="tit">姓名</div>
          <div class="name">{{ order_list.name }}</div>
        </div>
        <div class="user_list">
          <img src="../../../assets/order/user_job.png" alt="" />
          <div class="tit">岗位</div>
          <div class="name">客户经理</div>
        </div>
        <div class="user_list">
          <img src="../../../assets/order/user_tel.png" alt="" />
          <div class="tit">电话</div>
          <div class="name">{{ order_list.phone }}</div>
        </div>
        <div class="user_list">
          <img src="../../../assets/order/user_time.png" alt="" />
          <div class="tit">工作时间</div>
          <div class="name">9:00-22:00</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
export default {
  data() {
    return {
      order_list: [],
      isLoading: true,
      pages: 1,
      mes: '',
    };
  },
  created() {
    this.getorder();
  },
  methods: {
    getorder() {
      get('api/inv/selectManager').then((res) => {
        this.isLoading = false;
        this.order_list = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.right {
  width: 1006px;
  margin-left: 15px;
  background: #fff;
  padding-top: 34px;
  padding-left: 27px;
  height: 580px;
  .spen {
    font-size: 24px;
    font-weight: 600;
    color: #616161;
  }
  .user {
    width: 414px;
    height: 426px;
    border: 1px solid #e4edf4;
    margin: 40px auto;
    .uese_pic {
      width: 414px;
      height: 213px;
      display: flex;
      justify-content: center;
      align-items: center;
      .img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .user_job {
      margin: 15px auto;
      .user_list {
        display: flex;
        font-size: 18px;
        margin-left: 90px;
        margin-top: 20px;
        img {
          width: 22px;
          height: 24px;
        }
        .tit {
          margin-left: 36px;
          color: #777;
        }
        .name {
          color: #333;
          margin-left: 15px;
        }
      }
    }
  }
}
</style>
